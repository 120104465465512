import React, { useEffect, useState, useMemo } from 'react'
import './overlay.css';
import './form.css';
import './UpcomingEvent.css';
import { RiCalendarScheduleLine } from "react-icons/ri";
import { MdOutlineOndemandVideo } from "react-icons/md";
import { PiClockCountdown } from "react-icons/pi";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Research_Pro_Logo from "../assets/Research_Pro_Logo_2.png";
import ResearchProposal from "../assets/ResearchProposal.jpg";
import axios from 'axios';
import { useLocation } from 'react-router-dom';
const UpcomingEvent = () => {
    const [latestEvent, setLatestEvent] = React.useState({});
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [isPayPalVisible, setIsPayPalVisible] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
   // const [formattedDate, setFormattedDate] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        //  console.log(latestEvent);
        const Event_id = latestEvent?._id;
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user_details_on_Event`, { name, email, Event_id });
            if (response.status === 200) {
                //   console.log("response")
                localStorage.setItem('email', email);
                setIsPayPalVisible(true);
                setIsFormSubmitted(true);
            }
            console.log(name, email);
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const location = useLocation();

    useEffect(() => {
        async function fetchLatestEvent() {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/get-latest-event`);
                // console.log(response);
                // console.log("response", response.data);
                const event = response.data;
                setLatestEvent(event);
                // console.log(event);

                // Convert Buffer to Blob and then to Object URL

            } catch (error) {
                console.error('Error fetching latest event:', error);
            }
        }
        fetchLatestEvent();
        if (location.pathname === '/upcoming') {
            document.body.style.backgroundColor = '#ffffff';
        } else {
            // Reset to default or another color if needed
            document.body.style.backgroundColor = '#d7c6f3';
        }

        return () => {
            document.body.style.backgroundColor = '';
        };

    }, [location.pathname]);

    // useEffect(() => {
    //     const getFormattedDate = () => {
    //         const today = new Date();
    //         const day = today.getDate() + 1;
    //         const month = today.getMonth() + 1;
    //         const year = today.getFullYear();
    //         return `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
    //     };

    //     setFormattedDate(getFormattedDate());
    // }, []);

    const getTomorrowDateInGMT = () => {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);
    
        const day = tomorrow.getUTCDate();
        const month = tomorrow.toLocaleString('default', { month: 'long', timeZone: 'UTC' });
        const year = tomorrow.getUTCFullYear();
    
        // Add suffix for day (st, nd, rd, th)
        const getDayWithSuffix = (day) => {
          if (day > 3 && day < 21) return `${day}th`; // Catch exceptions like 11th, 12th, 13th
          switch (day % 10) {
            case 1: return `${day}st`;
            case 2: return `${day}nd`;
            case 3: return `${day}rd`;
            default: return `${day}th`;
          }
        };
    
        return `${getDayWithSuffix(day)} ${month}, ${year}`;
      };
        
      

    const convertBufferToBlobUrl = (buffer, contentType) => {
        // console.log("Buffer:", buffer);
        // console.log("Content Type:", contentType);
        if (buffer && contentType) {
            const byteArray = new Uint8Array(buffer.data);
            const blob = new Blob([byteArray], { type: contentType });
            const blobUrl = URL.createObjectURL(blob);
            // console.log("Generated Blob URL:", blobUrl);
            return blobUrl;
        }
        return null;
    };

    const imageSrc = useMemo(() => {
        return convertBufferToBlobUrl(latestEvent.image?.data, latestEvent.image?.contentType);
    }, [latestEvent.image?.data, latestEvent.image?.contentType]);


    useEffect(() => {
        if (isPayPalVisible) {
            const script = document.createElement('script');
            script.src = "https://www.paypal.com/sdk/js?client-id=BAAYWXYvMr4CcyPUugFMlYcVLnq3IJBmWAmkw2ExaA4hGWM83mOa4sfikVNFUT8HkyjKKC22ihzRZoLWSw&components=hosted-buttons&disable-funding=venmo&currency=USD";
            script.addEventListener('load', () => {
                window.paypal.HostedButtons({
                    hostedButtonId: "YHVL7FNXJFSJA",
                }).render("#paypal-container-YHVL7FNXJFSJA");
            });
            document.body.appendChild(script);
        }
    }, [isPayPalVisible]);


    return (
        <>
            {latestEvent && (
                <div className="upcoming-event-container">
                    <div className="header">
                        {!ResearchProposal ? (
                            <div className="skeleton-loader">Loading...</div>
                        ) : (
                            <img loading="lazy" src={ResearchProposal} alt="EventImage" />
                        )}
                    </div>

                    {/* <div className="header">
            <div className="headerJustButton"><button>Just added!</button></div>
            <div className="buttons">
                <button>&#10084;</button>
                <button>&#8682;</button>
            </div>
        </div> 
         */}
                    <div className="event-details">
                        <div className="event-details-header">
                            <div className="left-half-event-details">


                                {/* <div className="date">Monday, July 2024</div>  */}
                                <h2 className="page-title">Unlock the Secrets to Designing Winning Proposals with AI: A
                                    2-Day Workshop
                                </h2>
                                <div className="page-subtitle"><strong>Are you ready to transform your proposal-writing skills and leverage the power
                                    of AI to craft impactful research proposals? Join our exclusive 2-day workshop
                                    where you'll not only learn the art of proposal design but also gain hands-on
                                    experience with the latest AI tools!
                                    .</strong></div>
                            </div>
                            <div className="right-half-event-details">
                                <div className="payment-info">
                                    <div className="box larger-than-800">
                                        <div> <span className="original-price">$99.99</span> <span
                                            className="discounted-price">$49.99</span> </div>
                                        <a className="button" href="#popup1"><button> Get Tickets</button></a>
                                    </div>
                                </div>
                            </div>

                            {/* Second box for screen sizes less than 800px  */}

                            <div className="box smaller-than-800">
                                <div> <span className="original-price">$99.99</span> <span className="discounted-price">$49.00</span> </div>
                                <a className="button" href="#popup1"><button>Get Tickets</button></a>
                            </div>

                            <div id="popup1" className="overlay">
                                <div className="popup">
                                    <h2>Get Tickets</h2>
                                    <a className="close" href="#a">×</a>
                                    <div className="content">
                                        {!isFormSubmitted && (
                                            <form name="contact" method="POST" netlify data-netlify="true" onSubmit={(event) => handleSubmit(event)}>
                                                <input type="hidden" name="form-name" value="contact" />
                                                <label htmlFor="name">Name:</label>
                                                <input type="text" id="name" name="name" onChange={(e) => setName(e.target.value)} required />
                                                <label htmlFor="email">Email:</label>
                                                <input type="email" id="email" name="email" onChange={(e) => setEmail(e.target.value)} required />
                                                <button type="submit">Continue Checkout</button>
                                            </form>
                                        )}

                                        {isPayPalVisible && (
                                            <div id="paypal-container-YHVL7FNXJFSJA" style={{ display: "block" }}></div>
                                        )}
                                    </div>
                                </div>
                            </div>


                        </div>
                        <h2>Date and Time</h2>
                        <div className="date-info">
                            <i className="logo">
                                <RiCalendarScheduleLine size={20} />
                            </i>
                            <p className="date_info_full">
                                {/* {formattedDate}*/} 
                                {/* {25th Sept, 6:00 PM (Saudi Arabia Standard Time), 7:00 PM (UAE Time) }*/} 
                                <p>
                                {getTomorrowDateInGMT()}
                                </p>
                                <small>
                            We will connect and schedule a time as per your convenience.
                            </small>
                            </p>
                            
                        </div>

                        <div className="key-takeaways">
                            <h2> Workshop Highlights:</h2>
                            <ol>
                                <li><strong> Dates:</strong> Complete your workshop anytime within two days.</li>
                                <li><strong>Duration:</strong> Just 2 hours per day, with the flexibility to finish both days' tasks
                                and videos in one go if you prefer.</li>
                                <li><strong>Format: </strong>Recorded sessions, practical tasks, and convenient online submission.</li>
                                <li><strong>Cost:</strong>$49.99 Only.</li>
                            </ol>
                        </div>

                        <div className="key-takeaways">
                            <h2>What You’ll Gain:</h2>
                            <ol>
                                <li><strong> Crafting a Compelling Proposal:</strong> Dive deep into the essentials of proposal
                                    writing, including creating a powerful abstract, literature review, methodology,
                                    and outcomes. Learn how to structure your proposal for maximum impact.</li>
                                <li><strong>Strategic Topic Selection:</strong> Discover strategies to choose a topic that is
                                    original, impactful, and perfectly aligned with your research goals.</li>
                                <li><strong>Ensuring Feasibility:</strong>Learn to evaluate your topic’s feasibility, balancing
                                    resources, timelines, and expertise to avoid overcommitment.</li>
                                <li><strong>Avoiding Common Pitfalls:</strong>Identify and steer clear of frequent mistakes
                                    made during proposal writing.</li>
                                <li><strong>Aligning with Academic Goals:</strong> Gain insights into how to align your proposal
                                    with academic standards and make a significant contribution to your field.</li>
                            </ol>
                        </div>

                        <div className="key-takeaways">
                            <h2> Interactive Learning Experience:</h2>
                            <ol>
                                <li><strong>Hands-On Practice:</strong> Engage with practical tasks and real-world scenarios using
                                    AI tools designed to enhance your proposal writing.</li>
                                <li><strong>Flexible Learning:</strong> Access recorded sessions at your convenience and submit
                                    your tasks through an easy online form.</li>
                                <li><strong>Certification:</strong>Receive a certificate of completion upon successful task
                                    submission, adding value to your academic and professional portfolio</li>
                            </ol>
                        </div>

                        <div className="key-takeaways">
                            <h2> How It Works:</h2>
                            <ol>
                                <li><strong>Enroll Now:</strong> Secure your spot at the workshop for just $49.99.</li>
                                <li><strong>Get Started:</strong> Upon registration, you'll receive all the workshop materials and
                                access to the recorded sessions via email.</li>
                                <li><strong>Complete at Your Pace:</strong>Spend 2 hours each day on the content, or finish it all
                                in one day if you prefer. Complete your tasks and submit them online.</li>
                                <li><strong>Earn Your Certificate: </strong>Successfully submit your tasks and receive your
                                certificate, showcasing your enhanced skills and knowledge.</li>
                            </ol>
                        </div>

                        <h2>Ready to Master Proposal Writing with AI?</h2>
                        <p className='web-certificate'>Don’t miss this opportunity to elevate your proposal writing skills and embrace
                        the future with AI. Spots are limited, so act fast!</p>


                        <h2>For More Information:</h2>
                        <p className='web-certificate'>Check your email for workshop details, recorded sessions, and task
                        submissions. We’re here to support you every step of the way!</p>
                        <p className='web-certificate'>Transform your proposal writing skills today. Join us for this innovative and
                        flexible workshop experience and become a master of proposal design with AI!</p>

                        <h2>Location</h2>
                        <div className="location">
                            <div className="logo"><i className="icon">
                                <MdOutlineOndemandVideo size={20} />

                            </i></div>

                            <div className="location_text_info">
                                <div>Online </div>
                                <div className="text">Link will be mailed to you an hour before the webinar </div>
                            </div>


                        </div>



                        {/* <h2>About This Event</h2>
                        <div className="about-event">
                            <div className="about-event-icon">
                                <i className="blue-icon">

                                    <PiClockCountdown />
                                </i>
                            </div>
                            <div className="about-event-text">
                                Event lasts for 1 hour.
                            </div>
                        </div>

                        <div className='about-paragraph'>
                            <p>Be a part of the ground-breaking seminar online, where our panel of expert speakers discuss the latest topics and trends in gut health, mental health, and nutrition.
                            </p>
                            <p>Online viewers will have access to the series of insightful discussion sessions and will also have the opportunity to take part in a live Q&A session. The Goodwood Health Summit, presented by Randox Health, on <strong>Thursday 19 September</strong>, will be live streamed from Goodwood Estate in West Sussex.</p>
                            <p><strong>Justin Webb</strong> will lead the conversation with a world-class line-up of speakers, including,<strong> Dr Chris van Tulleken, Dr Sioned Jones, Dr James Kinross and Professor Louise Kenny</strong>, and discuss evidence around early year feeding and environment.</p>
                            <p>As authorities in their respective fields, our speakers will share their research and knowledge on the microbiome and how it is affected by the choices we make about feeding from birth onwards. They will consider the challenges and solutions to the overall medical and societal understanding of this crucial subject.</p>
                            <p>The Summit will cover a broad range of topics including lifestyle changes, education and awareness, commercial and political drivers and the resultant impact on our long term physical and mental health.</p>
                            <p>The online summit, partnered with Randox Health, responds to the need for widespread understanding, research, education and communication on developments in infant and mother dietary health.</p>
                            <p className='itali'>We are delighted to present the Goodwood Health Summit, working towards a shared goal of improving gut health and highlighting the fundamental role the gut plays in our overall wellbeing.


                                In recent years we have seen a highly notable change in the people’s behaviours as they seek to better understand their bodies better, and we want to stress the importance of taking pro-active measures to ensure good health & well-being now and in the future.' Randox Health.</p>
                        </div>
                        <div className='useful-info'>
                            <h4>Useful Information</h4>
                            <p>on</p>
                            <ul>
                                <li>This online summit is split into three sessions – one ticket gives online access to all three sessions, with a break in between each.</li>
                                <li>Once your virtual ticket has been purchased, you will receive a dedicated Zoom link to access the seminar online on 19 September</li>
                                <li>There will be a chance to submit questions for the live Q&A session, through your booking confirmation</li>
                            </ul>
                        </div> */}

                        {/* <div className="about-event-description">
                            <ReactMarkdown remarkPlugins={[remarkGfm]}>{latestEvent.about}</ReactMarkdown>

                        </div> */}


                        {/* <div className="tags">
                            <h3>Tags</h3>
                            <div className="tags-list">
                                <div className="tag">research</div>
                                <div className="tag">masters</div>
                                <div className="tag">writing</div>
                                <div className="tag">phd</div>
                                <div className="tag">#proposal</div>
                                <div className="tag">#feasibility</div>
                                <div className="tag">#topic</div>
                                <div className="tag">#phdresearch</div>
                                <div className="tag">#phdstudent</div>
                                <div className="tag">#masters_degree</div>
                               
                            </div>
                        </div> */}
                        <h3>Organised By</h3>
                        <div className="organised-by">
                            <div className="event-details-header">
                                <div className="left-half-event-details">

                                    <img src={Research_Pro_Logo} alt="Location Icon" className="rounded-image"
                                        style={{ "verticalAlign": "middle", "marginRight": "10px", "height": "60px" }} /><span>Research Pro
                                    </span>

                                </div>
                                <div className="right-half-event-details">
                                    <div className="contact-info">

                                        <a href="mailto:youremail@example.com" className="contact-button">Contact Us</a>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <h3 className="about-orgainser">About Organisers</h3>
                            Research Pro is the ultimate platform for mastering research skills. With over 7,000 scholars trained,
                            our platform offers expert-led webinars on proposal writing, research paper crafting, data analysis, and
                            more. Elevate your research skills with proven professionalism and practical insights. Join Research Pro
                            and unlock your full potential today!


                        </div>
                    </div>



                </div>
            )}
        </>
    )
}

export default UpcomingEvent